import * as React from 'react';
import {
    useUpdate,
    useRefresh,
    useDelete,
    useNotify,
    useRecordContext,
    useResourceContext,
} from 'react-admin';
import { useBQStyles } from '../../themes';
import DraggableDialog from './ConfirmationDialog';
import { prepareFormData } from '../../utils/transforms';
import { getFromCache } from '../../utils/globals';

export const SoftDeleteListButton = ({ id, nameField, title, onConfirm }) => {
    const record = useRecordContext()
    const resource = useResourceContext();
    const refresh = useRefresh();
    const notify = useNotify()
    const [open, setOpen] = React.useState(false);
    const classes = getFromCache('bqClasses');
    const recordToUpdate = { ...record };

    delete recordToUpdate.treatments
    delete recordToUpdate.treatmentProtocol
    delete recordToUpdate.name_filter

    const isCognitoUser = resource === 'CognitoUsers';

    const objectName = record?.[nameField] || ''
    const actionName = title?.toLowerCase() || 'delete'

    Object.keys(recordToUpdate).forEach(field => {
        if (field.match(/_scan/i)) {
            delete recordToUpdate[field]
        }
    })

    const [softDelete, { loading }] = useUpdate(
        resource,
        {
            id: recordToUpdate.id,
            data: {
                ...prepareFormData(recordToUpdate),
                ...(!isCognitoUser && { deletedAt: parseInt(new Date().getTime() / 1000) }),
            }
        })

    const [softDeleteUser, { loadingUsers }] = useDelete(
        resource,
        {
            id: recordToUpdate.id,
            previousData: recordToUpdate
        });


    const softDeleteActions = async (e) => {
        e.stopPropagation();
        setOpen(false)
        if (onConfirm) {
            return onConfirm()
        }
        if (isCognitoUser) {
            await softDeleteUser()
            setTimeout(() => {
                refresh()
                notify(`User ${objectName} was deactivated`, { type: 'success' })
            }, 500)
        } else {
            await softDelete()
            setTimeout(() => {
                refresh()
                notify(`${objectName} was deleted`, { type: 'success' })
            }, 500);
        }
    }
    return (
        <div>
            <div
                id={id}
                className={classes.removeButton}
                record={record}
                title={title || 'Delete'}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true)
                }}
                disabled={loading}>
            </div>
            <DraggableDialog
                open={open}
                handleClose={(e) => {
                    e.stopPropagation();
                    setOpen(false)
                }
                }
                title={`${title || 'Delete'} ${objectName}`}
                content={`Are you sure you want to ${actionName} ${objectName}?`}
                onConfirm={(e) => softDeleteActions(e)}
                confirmText="Yes, I'm sure"
            />
        </div>)
}