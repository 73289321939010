/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    DataProviderContext,
    ListContextProvider,
    Datagrid,
    useList,
    useListContext
} from 'react-admin';

import { BQProgress, BQSection, BQSideBySide, BQToolbar } from './Generic/BQUI';
import { BQFilterInput } from './Generic/bq-filter-components';
import { useBQStyles } from '../themes';
import { BQExporter } from '../utils/bq_export';
import { getDateTime } from '../utils/textUtils';
import { FunctionField } from './Generic/bq-form-components';
import BQCRUDBuilder from './Generic/BQCRUDComponent';
import { getFromCache } from '../utils/globals';

let dataFromServer = null
let updateInverval = null
let internalUpdateInterval = null
let updateFilterInterval = null

let bqExporter = new BQExporter()

const MobileDeviceList = () => {
    const dataProvider = React.useContext(DataProviderContext);

    const [listData, setListData] = React.useState({ isLoading: true })
    const [filter, setFilter] = React.useState({})
    const filterObject = {}

    React.useEffect(() => {
        if (!dataFromServer) {
            dataProvider.getList('mobileDevices', { pagination: { page: 1, perPage: 10000 } }).then(response => {
                dataFromServer = response?.data
                setListData({
                    isLoading: false,
                    data: dataFromServer,
                })
            })
        }
    }, [])

    React.useEffect(() => {
        clearTimeout(updateInverval)
        clearTimeout(internalUpdateInterval)
        if (!dataFromServer?.length) {
            return
        }
        setListData({
            isLoading: false
        })
        updateInverval = setTimeout(() => {
            setListData({
                isLoading: true,
            })
            internalUpdateInterval = setTimeout(() => {
                setListData({
                    isLoading: false,
                    data: dataFromServer?.filter((item) => {
                        let isMatch = true
                        Object.keys(filter).forEach((key) => {
                            const filterValue = filter[key]?.toLowerCase()
                            if (filterValue) {
                                isMatch = isMatch && item[key]?.trim() && item[key]?.toLowerCase()?.indexOf(filterValue) !== -1
                            }
                        })
                        return isMatch
                    }),
                })
            }, 200)
        }, 0)
    }, [filter])

    const updateFilter = () => {
        const isNewFilter = Object.keys(filterObject).some(key => filter[key] !== filterObject[key])
        if (isNewFilter) {
            clearTimeout(updateFilterInterval)
            updateFilterInterval = setTimeout(() => {
                setFilter({ ...filter, ...filterObject })
            }, 500)
        }
    }

    const classes = getFromCache('bqClasses')

    return <>
        <BQSection title="Filters" style={{ minWidth: '100%', marginTop: '16px' }}>
            <BQSideBySide style={{ width: '100%' }}>
                <table className={classes.BQSectionContent}>
                    <BQFilterInput source="deviceUser" filterObject={filterObject} onChange={() => updateFilter()} />
                    <BQFilterInput source="userOU" label="Clinic" filterObject={filterObject} onChange={() => updateFilter()} />
                    <BQFilterInput source="deviceId" filterObject={filterObject} onChange={() => updateFilter()} />
                    <BQFilterInput source="os" filterObject={filterObject} onChange={() => updateFilter()} />
                </table>
                <table className={classes.BQSectionContent}>
                    <BQFilterInput source="imei" filterObject={filterObject} onChange={() => updateFilter()} />
                    <BQFilterInput source="iccid" filterObject={filterObject} onChange={() => updateFilter()} />
                    <BQFilterInput source="serialNumber" filterObject={filterObject} onChange={() => updateFilter()} />
                    <BQFilterInput source="bqAppVersionName" label="BQ App version" filterObject={filterObject} onChange={() => updateFilter()} />
                </table>
            </BQSideBySide>
        </BQSection >
        {
            listData.isLoading
                ?
                <BQProgress />
                :
                <ListComponent listData={listData} />
        }
    </>

}

const DefaultValueField = (props) => <FunctionField {...props} defaultValue="-" />

const ModileDeviceDataGrid = () => {
    const { setSort } = useListContext();

    const exporterRef = bqExporter?.createRefForExport(`mobileDevices`)

    React.useEffect(() => {
        setSort({ field: 'deviceUser', order: 'ASC' })
    }, [])

    return <Datagrid
        bulkActionButtons={false}
        ref={exporterRef}
        setSort={setSort}
    >
        <DefaultValueField source="deviceUser" />
        <DefaultValueField source="userOU" label="Clinic" />
        <DefaultValueField source="lastSync" value={v => v == 0 ? '-' : getDateTime(v)} />
        <DefaultValueField source="imei" />
        <DefaultValueField source="iccid" />
        <DefaultValueField label="Device ID" source="deviceId" />
        <DefaultValueField source="serialNumber" />
        <DefaultValueField source="certificateSerialNumber" label="Client certificate" />
        <DefaultValueField source="os" />
        <DefaultValueField source="bqAppVersionName" label="BQ App version" />
    </Datagrid >
}

const ListComponent = (props) => {
    const calculatedListData = props?.listData?.data?.map((item) => ({
        ...item,
        userOU: item.userOU || '-',
        lastSync: item.lastSync ? new Date(item.lastSync) : 0,
        imei: item.imei || '-',
        iccid: item.iccid || '-',
        deviceId: item.deviceId || '-',
        serialNumber: item.serialNumber || '-',
        os: item.os || '-',
        bqAppVersionName: item.bqAppVersionName && item.bqAppVersionCode && `${item.bqAppVersionName} (${item.bqAppVersionCode})` || '-',
        certificateSerialNumber: item.certificateSerialNumberNumeric || item.certificateSerialNumber ? `${item.certificateSerialNumberNumeric} (${item.certificateSerialNumber})` : '-'
    }))
    return <>
        {
            calculatedListData?.length
                ?
                <ListContextProvider value={useList({
                    data: calculatedListData,
                    ids: calculatedListData?.map((item) => item.id),
                    sort: { field: 'deviceUser', order: 'ASC' }
                })}>
                    <BQToolbar
                        exporter={() => bqExporter.exportData(`mobileDevices`)}
                        label="Mobile Devices"
                        hideButtons
                        hideBackButton
                    />
                    <ModileDeviceDataGrid />
                </ListContextProvider >
                :
                <div id="no_devices_label">No devices</div>
        }
    </>
}

export default BQCRUDBuilder({
    Create: null,
    Edit: null,
    List: MobileDeviceList,
})