/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Edit,
    TextField,
    Create,
    DataProviderContext,
} from 'react-admin';

import { ExerciseCategoryCheckbox, getLists } from './Generic/TreatmentProtocolExerciseData';
import { getDuplicates, validate, validateDuplicates, validateNumber, validateString } from '../utils/validations';
import { useStyles } from '../utils/styles';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQCheckbox, BQLabel, BQSimpleForm, BQSimpleIterator } from './Generic/bq-form-components';
import BQIterator from './Generic/BQIterator';
import { BQModelList, BQSection, BQSideBySide, BQToolbar } from './Generic/BQUI';
import { BQDropDown, BQInput } from './Generic/bq-input';
import { getTranslatedName, getValueByPath } from '../utils/textUtils';
import { prepareFormData } from '../utils/transforms';
import BQCRUDBuilder from './Generic/BQCRUDComponent';
import * as deepcopy from 'deepcopy';

const confirmationObject = [
    {
        title: `Treatment Protocol Warning`,
        text: `We noticed that you didn't set any exercise for this protocol. Are you sure you want to proceed?`,
        condition: (bqForm) => !bqForm.treatmentStages?.
            filter(stage => stage?.exercisesCategories?.
                some(cat => cat.selectedExercisesIndexed.some(exercise => exercise.isEnabled)))
            ?.length
    }
]

const TreatmentProtocolList = () => (
    <BQModelList>
        <TextField source="name" />
        <TextField source="numberOfTreatments" label="Number of sessions" />
    </BQModelList>
)

const fieldNamesToWatch = ['treatmentStages']

const TreatmentProtocolEditor = props => {
    const { sessionsCounterTypes, choicesTreatmentStageType } = props.appSettings || {}

    const classes = useStyles();
    const { isLoadingDictionaries, cache, language: { code: langCode } } = props

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'Protocol',
        duplicates: props.duplicates
    }

    const [formWatch, setFormWatch] = React.useState({})
    const [bqForm, setBQForm] = React.useState({})
    React.useEffect(() => {
        const watchObject = {}
        fieldNamesToWatch.forEach((name, i) => watchObject[name] = formWatch[i])
        setBQForm(watchObject)
    }, [formWatch])

    return (
        <BQSimpleForm
            setWatch={{
                action: setFormWatch,
                name: fieldNamesToWatch
            }}
            toolbar={<BQToolbar type="Treatment Protocol" confirmation={confirmationObject} />}
        >
            <BQSideBySide>
                <BQSection title="Treatment Protocol Info" language={props.language}>
                    <BQInput source="name" validate={validateString} className={classes.textInput} duplicates={duplicatesCheckData} />
                    <BQInput source="numberOfTreatments" label="Number of sessions" type="number" validate={validateNumber} min={1} />
                    <BQInput source="totalDaysLimit" type="number" validate={validateNumber} min={0} defaultValue={0} />
                    <BQInput source="weeklySessionsLimit" type="number" validate={validateNumber} min={1} max={7} defaultValue={5} />
                    <BQInput source="partialSessionsAllowed" type="number" validate={validateNumber} min={0} defaultValue={5} />
                    <BQDropDown source="sessionsCounterType" choices={sessionsCounterTypes} validate={validateString} defaultValue={'TOTAL_SESSIONS'} />
                </BQSection>
                <BQSection title="Stages in Protocol" language={props.language}>
                    <br />
                    <BQLabel
                        id="totalSessionTime"
                        label={({ record }) => {
                            const totalSessionTime = record.treatmentStages?.reduce((acc, stage) => acc + parseInt(stage?.treatmentStageDuration || 0), 0) || 0
                            return `Total Session Time: ${totalSessionTime} minute${totalSessionTime !== 1 ? 's' : ''}`
                        }}
                    />
                    {!isLoadingDictionaries &&
                        <BQIterator source="treatmentStages" noNumbering bottomLine>
                            <BQDropDown source="treatmentStageId" choices={cache.treatmentStagesCache} label="Exercise Stage" validate={validate} allowDuplicates
                                onChange={(data) => handleStageChange({ ...data, cache })}
                            />
                            <BQInput type="number" source="treatmentStageDuration" label="Stage duration in minutes" validate={validateNumber} min={1} />

                            <BQDropDown source="type" choices={choicesTreatmentStageType} label="Stage Type" validate={validate} allowDuplicates />

                            <BQSimpleIterator getItemLabel={() => ''} source="exercisesCategories" style={{ marginLeft: '-42px' }}>
                                <ExerciseCategoryCheckbox langCode={langCode} cache={cache} />
                                <BQSimpleIterator source="selectedExercisesIndexed">
                                    <BQCheckbox source="isEnabled" label={({ record }) => {
                                        const exercise = cache.exercisesCache.find(exercise => exercise.id === record.exerciseId)
                                        return getTranslatedName(exercise, langCode)
                                    }} style={{ marginLeft: '16px' }} />
                                </BQSimpleIterator>
                            </BQSimpleIterator>
                        </BQIterator>
                    }
                </BQSection>
            </BQSideBySide>
        </BQSimpleForm>
    )
}

const handleStageChange = ({ event, selection, formContext, source, cache }) => {
    if (!source) {
        return
    }
    const sourceToChange = source.split('.')[0]
    if (!selection) {
        formContext.setValue(sourceToChange, {}, { shouldTouch: true, shouldDirty: true })
        return
    }
    const index = parseInt(source.match(/\[(\d+)\]/i)[1])
    const cacheCopy = deepcopy(cache)
    const currentStage = getValueByPath(formContext.getValues(), sourceToChange)
    const selectedStage = cacheCopy.treatmentStagesCache.find(stage => stage.id === selection.id)
    const stageToAssign = {
        deviceEnabled: selectedStage.deviceEnabled,
        index,
        exercisesCategories: selectedStage.exerciseCategories.map((categoryId, categoryIndex) => {
            const categoryFromCache = cacheCopy.exercisesCategoriesCache.find(cat => cat.id === categoryId)
            return {
                index: categoryIndex,
                exerciseCategoryId: categoryId,
                selectedExercisesIndexed: categoryFromCache.exercises.map((exerciseId, exerciseIndex) => {
                    const exerciseFromCache = cacheCopy.exercisesCache.find(exercise => exercise.id === exerciseId)
                    return {
                        equipmentList: exerciseFromCache?.equipmentList,
                        index: exerciseIndex,
                        exerciseId,
                        isEnabled: true,
                        numOfRepetitions: exerciseFromCache?.numOfRepetitions,
                        numOfSets: exerciseFromCache?.numOfSets,
                        progressionsList: exerciseFromCache?.progressionsList,
                        secsInsteadOfReps: exerciseFromCache?.secsInsteadOfReps
                    }
                })
            }
        }),
        treatmentStageDuration: currentStage?.treatmentStageDuration,
        treatmentStageId: selectedStage.id,
        type: currentStage?.type
    }
    formContext.setValue(sourceToChange, stageToAssign, { shouldTouch: true, shouldDirty: true })
}

const mutationTransform = async (data, setDuplicates, cache) => {
    const duplicates = await getDuplicates('TreatmentProtocol', data, ['name']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    data.treatmentStages = data.treatmentStages && data.treatmentStages.filter(stage => cache.treatmentStagesCache.some(cachedStage => cachedStage.id === stage.treatmentStageId)).map((stage, stageIndex) => {
        return {
            index: stageIndex,
            treatmentStageId: stage.treatmentStageId,
            treatmentStageDuration: stage.treatmentStageDuration,
            type: stage.type,
            exercisesCategories: stage.exercisesCategories.filter(cat => cache.exercisesCategoriesCache.some(cachedCat => cachedCat.id === cat.exerciseCategoryId)).map((category, categoryIndex) => {
                const selectedExercisesIndexed = category.selectedExercisesIndexed.filter(exercise => cache.exercisesCache.some(cachedExercise => cachedExercise.id === exercise.exerciseId)).map((exercise, exerciseIndex) => { return { index: exerciseIndex, isEnabled: exercise.isEnabled, exerciseId: exercise.exerciseId } })
                return {
                    index: categoryIndex,
                    exerciseCategoryId: category.exerciseCategoryId,
                    selectedExercisesIndexed
                }
            })
        }
    })
    data.treatmentStages = data.treatmentStages || []
    delete data.totalSessionTime
    delete data.undefined
    return prepareFormData(data)
};

const TreatmentProtocolMutate = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();

    const dataProvider = useContext(DataProviderContext);
    const [state, setState] = useState({ isLoadingDictionaries: true });
    useEffect(() => {
        getLists(dataProvider, true).then(data => setState({
            isLoadingDictionaries: false,
            cache: data
        }))
    }, [dataProvider]);

    const stateRef = useRef()
    stateRef.current = state

    const editorProps = { ...props, ...editorGlobalProps(), transform: (data) => mutationTransform(data, setDuplicates, stateRef.current.cache) }

    return (
        <div>
            {isCreate ?
                (<Create {...editorProps} >
                    <TreatmentProtocolEditor {...props} redirect={bqCreateRedirection} {...state} duplicates={duplicates} />
                </Create>) :
                (<Edit {...editorProps} >
                    <TreatmentProtocolEditor {...props} redirect={bqEditRedirection} {...state} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const TreatmentProtocolCreate = props => TreatmentProtocolMutate(true, props);

const TreatmentProtocolEdit = props => TreatmentProtocolMutate(false, props);

export default BQCRUDBuilder({
    Create: TreatmentProtocolCreate,
    Edit: TreatmentProtocolEdit,
    List: TreatmentProtocolList,
})