import {
    TEMP_PASSWORD_GENERATED,
    TEMP_PASSWORD_UNHIDE,
    TEMP_PASSWORD_COPIED,
    TEMP_PASSWORD_CLEAR
} from '../actions';

export const tempPasswordGenerated = (username, tempPassword) => {
    return {
        type: TEMP_PASSWORD_GENERATED,
        payload: {
            username,
            password: tempPassword
        },
    }
}

export const tempPasswordUnhide = (state) => {
    return {
        type: TEMP_PASSWORD_UNHIDE,
        payload: {
            state
        }
    }
}

export const tempPasswordCopied = () => {
    return {
        type: TEMP_PASSWORD_COPIED
    }
}

export const tempPasswordClear = () => {
    return {
        type: TEMP_PASSWORD_CLEAR,
    }
}