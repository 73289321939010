import React from 'react';
import './index.css';
import App from './App';
import { BQAuthenticator } from './components/auth/BQAuthenticator';
import { endVideoMeeting } from './utils/bq-zoom';
import { resetSessionTimeout } from './utils/constants';
import BQDataProvider from './BQDataProvider';
import { useBQStyles } from './themes';
import { addToCache, getFromCache } from './utils/globals';
import { createRoot } from 'react-dom/client';

if (window.location.href.match(/(?<=(\?|&)).*?(filter|filters).*?\=.*?(&|$)/ig)) {
    window.location = window.location.href.replace(/(?<=(\?|&)).*?(filter|filters).*?\=.*?(&|$)/ig, '')
}

const baseError = console.error;
console.error = function filterWarnings(msg) {
    const supressedWarnings = ['Warning:', 'Material-UI'];
    try {
        if (!supressedWarnings.some(entry => msg && msg.includes(entry))) {
            baseError.apply(console, arguments);
        } else {
            console.warn(msg);
        }
    }
    catch {
        baseError.apply(console, arguments);
    }
};

const MainComponent = () => {
    if (!getFromCache('bqClasses')) {
        addToCache('bqClasses', useBQStyles())
    }
    return (
        <BQAuthenticator>
            <App />
        </BQAuthenticator>
    )
}

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <MainComponent />
    </React.StrictMode>
);

window.onbeforeunload = function (e) {
    endVideoMeeting()
    sessionStorage.setItem('reload', new Date().getTime())
    BQDataProvider.saveState()
};

window.onmousemove = function (e) {
    resetSessionTimeout()
}

window.onkeydown = function (e) {
    resetSessionTimeout()
}

resetSessionTimeout()