import { useEffect, useState } from "react"
import { useRedirect } from "react-admin"
import { useLocation } from 'react-router-dom';
import { clearFormsData } from "../../utils/bq-auth";

export const useBQRouter = (props) => {
    const [availableResources, setAvailableResources] = useState()
    const [resourceName, setResourceName] = useState()
    const location = useLocation()
    const redirect = useRedirect()

    useEffect(() => {
        const currentResource = location.pathname.split('/')[1].toLowerCase()
        if (availableResources?.length && availableResources.indexOf(currentResource) === -1) {
            redirect(availableResources[0])
        }
        if (currentResource !== resourceName) {
            clearFormsData()
            setResourceName(currentResource)
        }
    }, [availableResources, location])

    useEffect(() => {
        const tempAvailableResources = props?.children?.props?.children
            ?.find(item => Array.isArray(item) && item.some(res => {
                const { edit, create, list, view } = res.props.element.props
                return (edit || create || list || view)
            }))
            ?.map(item => {
                const { name, edit, create, list, view } = item.props.element.props
                return (edit || create || list || view) && name.toLowerCase() || null
            })
            ?.filter(item => item)
        setAvailableResources(tempAvailableResources)
    }, [])
}