import { Table, TableBody, TableHead } from '@material-ui/core';
import React, { useState } from 'react';
import { Button } from 'ra-ui-materialui';
import { SoftDeleteListButton } from './SoftDeleteListButton';
import { getLabel } from '../../utils/textUtils';
import { useFormContext, useFieldArray } from 'react-hook-form'
import { getFromCache } from '../../utils/globals';

function BQEditableDataGrid(props) {
    const {
        disableReorder,
        disableRemove,
        disableAdd,
        readOnly,
        allEditable
    } = props
    const [editedIndex, setEditedIndex] = useState();
    const classes = getFromCache('bqClasses');

    const { fields, append, remove, swap } = useFieldArray({
        control: useFormContext().control,
        name: props.source
    })

    const handleAddItem = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditedIndex(fields.length);
        append({})
    }

    const handleRemoveItem = (index) => {
        remove(index)
    }

    const handleMoveUp = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        index > 0 && swap(index, index - 1)
    }

    const handleMoveDown = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        index < fields.length - 1 && swap(index, index + 1)
    }

    const handleEdit = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        setEditedIndex(index);
    }

    const handleDoneEditing = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditedIndex();
    }

    return (
        <>
            {!disableAdd && !readOnly && <div style={{ margin: "16px -62px" }}>
                <div className={classes.addButton} style={{ transform: 'translateX(42px) translateY(10px)' }}></div>
                <Button
                    id="add_button"
                    label="Add"
                    onClick={handleAddItem}
                    className='RaListToolbar-floating'
                />
            </div>
            }

            <Table style={{ width: '100%' }}>
                <TableHead className={classes.BQTableHeader}>
                    <th style={{ maxWidth: '32px', minWidth: '32px', paddingLeft: '8px', textAlign: 'left', verticalAlign: 'top' }}>#</th>
                    {createHeader(props)}
                    <th style={{ width: '0px' }}></th>
                </TableHead>

                <TableBody>
                    {
                        fields?.map((_, index) =>
                            <tr className={classes.bqTableRow}>
                                <td className={classes.editableListNumberingColumn}><div className={classes.editableListNumbering}>{index + 1}</div></td>
                                {createChildren({ ...props, index, editedIndex, classes, allEditable: !readOnly && allEditable, readOnly })}
                                <td>
                                    <table style={{ width: '88px', minWidth: '88px' }}>
                                        <tr>
                                            {!readOnly && !allEditable && <td
                                                id={`${index !== editedIndex ? `edit_` : 'done_'}${index}`}
                                                className={index !== editedIndex ? classes.editButton : classes.saveButton}
                                                title={index !== editedIndex ? 'Edit' : 'Save'}
                                                onClick={(e) => index !== editedIndex ? handleEdit(e, index) : handleDoneEditing(e, index)}
                                            >
                                            </td>}
                                            {(!disableReorder && !readOnly) && <td className={`${classes.upButton} ${classes.thinButton}`} onClick={(e) => handleMoveUp(e, index)} title={'Move up'}></td>}
                                            {(!disableReorder && !readOnly) && <td className={`${classes.downButton} ${classes.thinButton}`} onClick={(e) => handleMoveDown(e, index)} title={'Move down'}></td>}
                                            {(!disableRemove && !readOnly) && <td className={classes.editableListNumberingColumn}><SoftDeleteListButton id={`delete_button_${index}`} title="Delete Item" onConfirm={(e) => handleRemoveItem(index)} /></td>}
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        )
                    }
                </TableBody>
            </Table>
        </>
    );
}

const createHeader = ({ children: baseChildren }) => {
    const children = baseChildren.filter(child => child);
    return children.map(child => {
        return <th style={{
            ...child.props.style,
            textAlign: 'left',
            lineHeight: '1.6em',
            ...(child.props.width ? { width: `${child.props.width}px` } : { width: `auto` }),
            ...(child.props.width ? { minWidth: `${child.props.width}px` } : {}),
            ...(child.props.width ? { maxWidth: `${child.props.width}px` } : {})
        }}>{`${getLabel(child)}${!!child.props.validate ? ' *' : ''}`}</th>
    })
}

const createChildren = ({ index, source, children: baseChildren, editedIndex, classes, allEditable, readOnly }) => {
    const children = baseChildren.filter(child => child);
    return React.Children.map(children.filter(child => child), child => {
        const sourcePrefix = `${source}[${index}].`
        const childSource = child.props.source.replace(sourcePrefix, '')
        const childComponent = React.isValidElement(child) ? React.cloneElement(child, {
            ...child.props,
            source: `${sourcePrefix}${childSource}`,
            noLabel: true,
            readOnly: child.props.readOnly || readOnly || (allEditable ? false : index !== editedIndex)
        }) : child;
        //console.log(`${sourcePrefix}${childSource}`, child.props.source)
        return <td className={classes.editableListDataColumn} style={child.props.style}><table style={child.props.style || { width: '100%' }}>{childComponent}</table></td>
    })
}

export default BQEditableDataGrid;