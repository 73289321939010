/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    TextField,
    Create,
    Edit,
    LinearProgress,
    DataProviderContext
} from 'react-admin';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { getTranslatedName, limitText } from '../utils/textUtils';
import { getDuplicates, validate, validateDuplicates, validateString } from '../utils/validations';
import { BQSimpleForm, FunctionField, TranslationInput } from './Generic/bq-form-components';
import BQIterator from './Generic/BQIterator';
import { BQModelList, BQSection, BQSideBySide, BQToolbar } from './Generic/BQUI';
import { getLists } from './Generic/TreatmentProtocolExerciseData';
import { BQDropDown } from './Generic/bq-input';
import { prepareFormData } from '../utils/transforms';
import BQCRUDBuilder from './Generic/BQCRUDComponent';

const TreatmentStageList = (props) => {
    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'

    return <BQModelList>
        <TextField source="name" />
        {isEnglish ? <FunctionField source="description" value={v => limitText(v)} /> : <FunctionField source="nameTranslations" label={`Name - ${langName}`} value={v => v?.find(t => t.code === langCode)?.text} />}
    </BQModelList>
}

const treatmentStageTransform = async (data, setDuplicates, cache) => {
    const duplicates = await getDuplicates('TreatmentStage', data, ['name']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }
    data.exerciseCategories = data.exerciseCategories && [...new Set(data.exerciseCategories.filter(catId => cache.exercisesCategoriesCache.some(cachedCategory => cachedCategory.id === catId)))] || []
    return prepareFormData(data)
}

const TreatmentStageEditor = props => {
    const { isLoadingLists, cache, language } = props;
    const { code: langCode } = language || {}
    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'Stage',
        duplicates: props.duplicates
    }



    return (

        <BQSimpleForm toolbar={<BQToolbar type="Exercise Stage" />}>
            <BQSideBySide>
                <BQSection title="Exercise Stage Info" language={props.language}>
                    <TranslationInput source="name" validate={validateString} duplicates={duplicatesCheckData} />
                    <TranslationInput multiline source="description" />
                </BQSection>

                <BQSection title="Categories in Stage" language={props.language}>
                    {isLoadingLists ?
                        <LinearProgress /> :
                        <div style={{ width: '100%' }}>
                            <BQIterator source="exerciseCategories">
                                <BQDropDown
                                    noLabel
                                    validate={validate}
                                    minWidth={360}
                                    choices={cache?.exercisesCategoriesCache}
                                    optionText={(item) => getTranslatedName(item, langCode)}
                                />
                            </BQIterator>
                        </div>
                    }
                </BQSection>
            </BQSideBySide>
        </BQSimpleForm>
    )
}

const TreatmentStageMutation = (isCreate, props) => {
    const [duplicates, setDuplicates] = useState();

    const dataProvider = useContext(DataProviderContext);
    const [state, setState] = useState({ isLoadingDictionaries: true });
    useEffect(() => {
        getLists(dataProvider, true).then(data => setState({
            isLoadingDictionaries: false,
            cache: data
        }))
    }, [dataProvider]);

    const stateRef = useRef()
    stateRef.current = state

    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => treatmentStageTransform(data, setDuplicates, stateRef.current.cache) }
    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <TreatmentStageEditor {...props} redirect={bqCreateRedirection} {...state} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <TreatmentStageEditor {...props} redirect={bqEditRedirection} {...state} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const TreatmentStageCreate = (props) => TreatmentStageMutation(true, props)

const TreatmentStageEdit = (props) => TreatmentStageMutation(false, props)

export default BQCRUDBuilder({
    Create: TreatmentStageCreate,
    Edit: TreatmentStageEdit,
    List: TreatmentStageList
})