/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    Edit,
    useList,
    ListContextProvider,
    Datagrid,
    useRecordContext,
} from 'react-admin';
import { getDuplicates, validate, validateDuplicates, validateMAC, validateQuickConnectNumber, validateString } from '../utils/validations';

import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { bqAuthData } from '../utils/bq-auth';
import { BQDropDown, BQInput } from './Generic/bq-input';
import { prepareFormData } from '../utils/transforms';
import BQCRUDBuilder from './Generic/BQCRUDComponent';
import { getDateTime } from '../utils/textUtils';
import { BQSimpleForm, FunctionField } from './Generic/bq-form-components';
import { BQExporter } from '../utils/bq_export';

let bqExporter = new BQExporter()

const DeviceList = () => {
    const { isSuperAdmin, isViewer, subjectOrPatient } = bqAuthData;
    const isFullPermissions = isSuperAdmin || isViewer

    const exporterRef = bqExporter?.createRefForExport(`Devices List`)

    return (
        <BQModelList
            disableDelete={true}
            nameField="serialNumber"
            showDates={true}
            disableEdit
            dataGridRef={exporterRef}
            exporter={() => bqExporter.exportData(`Device list`)}
        >
            <TextField label="Serial number - main device" source="serialNumber" />
            {isFullPermissions && <TextField label="Serial number - Bluetooth label" source="quickConnectNumber" />}
            {isFullPermissions && <TextField label="MAC address" source="macAddress" />}
            <FunctionField label={subjectOrPatient} source="usage" value={(value) => value?.[0]?.patientIdNumber} />
            {isFullPermissions && <FunctionField label="Clinic" source="usage" value={(value) => value?.[0]?.organizationalUnit} />}
        </BQModelList>
    )
}

const deviceTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('Device', data, ['serialNumber', 'quickConnectNumber', 'macAddress']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    data.quickConnectNumber = data.quickConnectNumber?.toUpperCase();
    data.macAddress = data.macAddress && data.macAddress.toUpperCase();
    delete data.usage
    return prepareFormData(data)
}

const DeviceEditor = props => {
    const { deviceType } = props.appSettings || {}
    const { isSuperAdmin, isViewer } = bqAuthData;
    const isFullPermissions = isSuperAdmin || isViewer

    const record = useRecordContext() || {}

    const { serialNumber } = record

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'A device',
        duplicates: props.duplicates
    }

    return (
        <>
            <BQSimpleForm
                toolbar={
                    <BQToolbar
                        type="Device"
                        nameField="serialNumber"
                        hideButtons={!!record.id}
                        exporter={record.usage?.length ? () => bqExporter.exportData(`Device usage (${serialNumber})`) : null}
                        exporterLabel="Export List"
                    />
                }>
                {!record.id &&
                    <BQSection title="Device Info">
                        <BQInput source="serialNumber" label="Serial number - main device" validate={validateString}
                            duplicates={duplicatesCheckData}
                        />
                        {isFullPermissions && <BQInput source="quickConnectNumber" label="Serial number - Bluetooth label" validate={validateQuickConnectNumber} maxLength={4}
                            duplicates={duplicatesCheckData}
                        />}
                        {isFullPermissions && <BQInput source="macAddress" label="MAC address" validate={validateMAC}
                            duplicates={duplicatesCheckData}
                        />}
                        {isFullPermissions && <BQInput source="resistance" validate={validate} />}
                        <BQDropDown source="deviceSize" label="Device type" choices={deviceType} unsorted validate={validate} />
                    </BQSection>
                }
                {record.id &&
                    <BQSection title="Device Info" style={{ maxWidth: '100%' }}>
                        <DeviceInfoInList />
                    </BQSection>
                }
                {!!record.usage?.length && <BQSection title="Device Usage" style={{ maxWidth: '100%' }}>
                    <DeviceUsageList />
                </BQSection>}
            </BQSimpleForm>
        </>
    )

}

const DeviceInfoInList = () => {
    const record = useRecordContext()
    const listContext = useList({
        data: [record],
    })

    const exporterRef = bqExporter?.createRefForExport(`Device Info`)

    return (
        <ListContextProvider value={listContext} >
            <Datagrid ref={exporterRef} bulkActionButtons={false}>
                <TextField source="serialNumber" label="Serial number - main device" />
                <TextField source="quickConnectNumber" label="Serial number - Bluetooth label" />
                <TextField source="macAddress" label="MAC address" />
                <TextField source="resistance" />
                <FunctionField source="deviceSize" label="Device type" fromAppSettings="deviceType" />
            </Datagrid >
        </ListContextProvider >
    )
}

const DeviceUsageList = () => {
    const { subjectOrPatient } = bqAuthData;
    const { usage } = useRecordContext()

    if (!usage) {
        return null
    }

    const exporterRef = bqExporter?.createRefForExport(`Device usage`)

    const parsedData = usage?.sort((a, b) => b.timestamp - a.timestamp)?.map((item, index) => ({ id: index, ...item }))
    const listContext = useList({
        data: parsedData,
        ids: parsedData?.map(item => item.id),
    })
    return (
        <ListContextProvider value={listContext} >
            <Datagrid ref={exporterRef} bulkActionButtons={false}>
                <FunctionField label="Date" source="timestamp" value={val => getDateTime(val)} />
                <TextField label="Tablet user" source="tabletUserEmail" />
                <TextField label="App user" source="userEmail" />
                <TextField label={subjectOrPatient} source="patientIdNumber" />
                <TextField label="Clinic" source="organizationalUnit" />
            </Datagrid >
        </ListContextProvider >
    )
}

const DeviceMutation = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();

    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => deviceTransform(data, setDuplicates) }

    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <DeviceEditor {...props} redirect={bqCreateRedirection} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <DeviceEditor {...props} redirect={bqEditRedirection} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const DeviceCreate = (props) => DeviceMutation(true, props)

const DeviceEdit = (props) => DeviceMutation(false, props)

export default BQCRUDBuilder({
    Create: DeviceCreate,
    Edit: DeviceEdit,
    List: DeviceList,
})
