import { bqAuthData } from './bq-auth';
import { isValidPhoneNumber } from 'react-phone-number-input'
import BQDataProvider from '../BQDataProvider';

export const validate = (value) => {
    if (value === undefined || value === null || value.length === 0) {
        return 'Required';
    }
    return null
};

export const validateNumber = (value, min, max) => {
    if (value === undefined || value === null || value === '' || isNaN(value)) {
        return 'Required';
    }
    try {
        if (min !== undefined && parseFloat(value) < min) {
            return `Value must be at least ${min}`
        } else if (max !== undefined && parseFloat(value) > max) {
            return `Value must be ${max} at most`
        }
    }
    catch {
        return 'Must be a number';
    }
    return null
};

export const validateNumberSet = (value, min, max) => {
    if (value?.split(',').some(element => isNaN(element) || parseInt(element).toString() !== element.trim()  || element < min || element > max)) {
        return `Values must be between ${min} and ${max} and separated by commas`
    }
    return null
};

export const validateYearOfBirth = (value) => {
    if (!value) {
        return 'Year is not valid';
    }
    const currentYear = parseInt(new Date().getFullYear());
    if (isNaN(value) || value > currentYear || value < currentYear - 150) {
        return 'Year is not valid'
    }
    return null
}

export const validateQuickConnectNumber = (value) => {
    if (!value || value.trim().length !== 4 || value.match(/\W/)) {
        return 'Must be 4 Letters/Numbers';
    }
    return null
}

export const validateMAC = (value) => {
    if (!value) {
        return 'Invalid MAC address';
    }
    const match = value.match(/([0-9a-fA-F]{2})/g);
    const parsedMAC = match && match.join(':');
    if (!parsedMAC || parsedMAC.length !== 17 || parsedMAC !== value) {
        return 'Invalid MAC address';
    }
    return null
}

export const validateNoSpaces = (value) => {
    if (!value) {
        return 'Required';
    }
    if (value.match(/\s/g)) {
        return 'Field cannot contain spaces';
    }
    return null
}

export const validateString = (value) => {
    if (!value) {
        return 'Required';
    }
    return null
}

export const validateUsename = (value) => {
    if (!value?.trim()?.match(/^[\w\d]+?(\.[\w\d]+)+$/)) {
        return 'Must be in the following format: First name.Last name (or initials, no spaces)';
    }
    return null
}

export const validatePhoneNumber = (value) => {
    return isValidPhoneNumber(value || '') ? null : 'Phone number is not valid'
}

export const validateIdNumber = (value) => {
    if (!value?.match(/^\d{3,3}?-\d{2,2}$/)) {
        return 'Must be in the following format: XXX-YY (all digits, no spaces)';
    }
    return null
}

export const validateDate = (value) => {
    const errorMessage = 'Must be in a date format, future date is not allowed'
    try {
        return value &&
            new Date(value) &&
            new Date(new Date(value).toDateString()).getTime() <= new Date(new Date().toDateString()).getTime() ? null : errorMessage
    } catch {
        return errorMessage
    }
}

export const validateHeight = (value) => {
    if (bqAuthData.isMarketTargetAudience) {
        if (isNaN(value) || value < 100 || value > 250) {
            return 'In cm (for example: 180). Valid values: 100 - 250';
        }
    } else {
        if (!value?.trim()?.match(/^\d+?['’`]\d{1,2}$/)) {
            return 'Must be in the following format: Feet’Inches (for example: 5’10)';
        }
    }
    return null
}

export const validateOU = (source) => {
    var regex = new RegExp(`^${bqAuthData.ou}(\\.\\w|$)`);
    if (source && !source?.trim()?.match(regex)) {
        return `You are trying to create an entity outside your clinic - ${bqAuthData.ou}. Please correct it and try again`
    } else if (source?.endsWith('.')) {
        return 'Clinic cannot end with a dot'
    }
    return null
}


export const validateEmail = (value) => {
    if (!value) {
        return 'Required';
    } else if (value?.match(/\s/)) {
        return 'Field cannot contain spaces'
    } else if (!value.trim().match(/^\S+@\S+\.\S+$/)) {
        return 'Email address not valid'
    } else {
        return null
    }
}

export const validateEmailArray = (value) => {
    return (!value?.length || value.some(item => !!validateEmail(item)))
        ? 'Invalid email' : null
}

export const validateDuplicates = (objectName, source, duplicates) => {
    const validationResult = duplicates && Object.keys(duplicates).some(key => duplicates[key] === source)
    return validationResult && `${objectName} with the same value already exists`
}

export const getDuplicates = async (objectName, data, fields) => {
    const fieldsToTest = []
    fields.forEach(field => fieldsToTest.push({
        field,
        value: data[field]
    }))
    const { items } = await BQDataProvider.detectDuplicates(objectName, data.id, fieldsToTest)
    return items
}