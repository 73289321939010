import { setOU } from '../components/organizationUnitSelector.component';

export const createTransform = ({ modules, ...data }) => {
    data.organizationalUnitId && setOU(data);
    data.deletedAt = 0;
    return data
};

export const editTransform = ({ modules, ...data }) => {
    data.organizationalUnitId && setOU(data);
    delete data.treatments
    delete data.treatmentProtocol
    return data
};

function removeField(obj, fieldToRemove) {
    for (let field in obj) {
        if (obj.hasOwnProperty(field)) {
            if (field === fieldToRemove) {
                delete obj[field];  // remove the field
            } else if (typeof obj[field] === 'object' && obj[field] !== null) {
                removeField(obj[field], fieldToRemove);  // recursive call for nested objects
            }
        }
    }
    return obj;
}

export const prepareFormData = (data) => {
    removeField(data, '__typename')
    removeField(data, 'undefined')
    data.deletedAt = data.deletedAt || 0
    Object.keys(data).filter(key => key.match(/_scan/)).forEach(key => delete data[key])
    return parseNumbers(data)
}

const parseNumbers = (data) => {
    if (typeof data === 'object' && data !== null) {
        if (Array.isArray(data)) {
            return data.map(item => parseNumbers(item));
        } else {
            const parsedObject = {};
            for (const key in data) {
                if (!isNaN(key)) {
                    parsedObject[key] = prepareNumber(data[key]);
                } else {
                    parsedObject[key] = parseNumbers(data[key]);
                }
            }
            return parsedObject;
        }
    } else if (!isNaN(data)) {
        return prepareNumber(data);
    } else {
        return data;
    }
};

const prepareNumber = (value) => {
    if (value === '') {
        return null;
    }
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) || `${parsedValue}` !== value ? value : parsedValue;
};