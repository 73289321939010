import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded"

const BQZoomDiv = document.getElementById('BQZoomDiv')

const sidebarWidth = 128

const videoSize = {
    width: 640,
    height: 480
}

let bqZoomClient = null
let metingWindowLocationInterval = null
let meetingWindow = null

export const initVideoClient = async () => {
    return new Promise((resolve, reject) => {
        endVideoMeeting()
            .catch(error => console.error(error))
            .finally(async () => {
                ZoomMtgEmbedded.destroyClient()
                await new Promise(resolve => setTimeout(resolve, 1000));

                bqZoomClient = ZoomMtgEmbedded.createClient()

                bqZoomClient
                    .init({
                        zoomAppRoot: BQZoomDiv,
                        language: 'en-US',
                        customize: {
                            video: {
                                defaultViewType: "gallery",
                                isResizable: true,
                                viewSizes: {
                                    default: videoSize
                                }
                            }
                        }
                    })
                    .then(result => {
                        console.log('Zoom initiated')
                        resolve(result)
                    })
                    .catch(error => reject(error))
            })
    })
}

export const startVideoMeeting = (params) => {
    return new Promise((resolve, reject) =>
        initVideoClient()
            .then(() => {
                console.log('Joinning a meeting')
                BQZoomDiv.style.top = `160px`
                BQZoomDiv.style.left = `${(window.innerWidth - videoSize.width + sidebarWidth) / 2}px`
                BQZoomDiv.style.transitionProperty = 'transform'
                BQZoomDiv.style.transitionDuration = '0.1s'
                bqZoomClient
                    .join(params)
                    .then(() => {
                        console.log('Joined meeting')
                        guardMeetingWindowLocation()
                        resolve()
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
            .catch((error) => {
                console.log(error)
                reject(error)
            }))
}

const guardMeetingWindowLocation = () => {
    clearInterval(metingWindowLocationInterval)
    metingWindowLocationInterval = setInterval(() => {
        if (!isVideoMeetingActive) {
            clearInterval(metingWindowLocationInterval)
            return
        }
        if (!meetingWindow) {
            meetingWindow = document.getElementsByClassName('react-draggable')[0]
        }
        if (meetingWindow?.className?.indexOf('dragging') === -1) {
            const minX = (window.innerWidth - meetingWindow.offsetWidth + sidebarWidth) / -2
            const minY = -160
            const meetingWindowTransform = meetingWindow?.style?.transform
            const meetingWindowTransformMatches = meetingWindowTransform?.match(/translate\((.*?)px, (.*?)px\)/)
            const meetingWindowTranslateX = parseInt(meetingWindowTransformMatches?.[1] || 0)
            const meetingWindowTranslateY = parseInt(meetingWindowTransformMatches?.[2] || 0)

            const bqZoomDivTransformMatches = BQZoomDiv?.style?.transform?.match(/translate\((.*?)px, (.*?)px\)/)
            const bqZoomDivTranslateX = parseInt(bqZoomDivTransformMatches?.[1] || 0)
            const bqZoomDivTranslateY = parseInt(bqZoomDivTransformMatches?.[2] || 0)

            if (meetingWindowTranslateX + bqZoomDivTranslateX < minX) {
                BQZoomDiv.style.transform = `translate(${((meetingWindowTranslateX * -1) + minX)}px, ${bqZoomDivTranslateY}px)`
            }

            if (meetingWindowTranslateX + bqZoomDivTranslateX > -minX) {
                BQZoomDiv.style.transform = `translate(${((meetingWindowTranslateX * -1) - minX - sidebarWidth - 16)}px, ${bqZoomDivTranslateY}px)`
            }

            if (meetingWindowTranslateY + bqZoomDivTranslateY < minY) {
                BQZoomDiv.style.transform = `translate(${bqZoomDivTranslateX}px, ${((meetingWindowTranslateY * -1) + minY)}px)`
            }

            if (meetingWindowTranslateY + bqZoomDivTranslateY > window.innerHeight - videoSize.height) {
                BQZoomDiv.style.transform = `translate(${bqZoomDivTranslateX}px, ${((meetingWindowTranslateY * -1) + videoSize.height)}px)`
            }
        }
    }, 100)
}

export const endVideoMeeting = async () => {
    console.log('Ending the zoom meeting if applicable')
    return bqZoomClient?.endMeeting()
}

export const isVideoMeetingActive = () => {
    return !!document.getElementById('zoom-sdk-video-canvas')
}