/* eslint-disable react/display-name */
import { Amplify } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { Admin, DataProviderContext, Layout, Resource, SimpleForm } from 'react-admin';
import awsExports from './aws-exports';

import CognitoUserComponent from './components/cognitoUser.components';
import LanguageComponent from './components/language.component';
import PatientComponent from './components/patient.component';
import OrganizationalUnitComponent from './components/organization.component';
import DeviceComponent from './components/device.component';
import ProgressionComponent from './components/progression.component';
import EquipmentComponent from './components/equipment.component';
import ExerciseComponent from './components/exercises.component';
import ExercisesCategoryComponent from './components/exercisesCategory.component';
import TreatmentStageComponent from './components/treatmentStage.component';
import TreatmentProtocolComponent from './components/treatmentProtocol.component';
import QuestionnaireComponent from './components/questionnaire.component';
import TextMessageComponent from './components/textMessage.component';
import AuditTrailList from './components/auditTrail.component'
import MobileDeviceList from './components/mobileDevice.component';

import { bq_theme } from './themes';
import * as Auth from '@aws-amplify/auth';
import { bqAuthData, clearBQAuthData, setBQAuthData } from './utils/bq-auth';

import langSelected from './redux/reducers/languageReducer'
import tempPasswordObject from './redux/reducers/tempPasswordReducer'
import { LanguageSelection } from './components/Generic/bq-form-components';
import { endVideoMeeting } from './utils/bq-zoom';
import AnalyticsComponent from './components/analytics.component';
import BQDataProvider from './BQDataProvider';
import TempPasswordDialog from './components/Generic/TempPasswordDialog';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';

import { useBQRouter } from './components/Generic/BQRouter';
import { getFromCache, useGetAppSettings } from './utils/globals';
import AdditionalResourcesSection from './components/Generic/additionalResource';
import AdditionalResource from './components/Generic/additionalResource';

Amplify.configure(awsExports);

const App = (props) => {

    const [appSettings, setAppSettings] = useState();
    const bqClasses = getFromCache('bqClasses');

    const [userName, setUserName] = useState();

    useEffect(() => {
        Auth.fetchAuthSession().then(autheticatedUser => {
            setBQAuthData(autheticatedUser)
            setUserName(bqAuthData.name);
        })
    }, [])

    const { checkAuthState } = props;

    const {
        userAllowed,
        isSuperAdmin,
        isInventory,
        isRandomizer,
        isAdmin,
        isOperator,
        isAuditor,
        isViewer,
    } = bqAuthData

    const patientViewAllowed = isSuperAdmin || isAdmin || isOperator || isViewer || isRandomizer

    if (!bqAuthData.id) {
        return <></>
    }

    return !bqAuthData.role ?
        <>Role is not set for current user</>
        :
        <Provider
            store={configureStore({ reducer: combineReducers({ langSelected, tempPasswordObject }) })}>
            <TempPasswordDialog />
            <AppSettingsComponent setAppSettings={setAppSettings} dataProvider={BQDataProvider} />
            {!!appSettings && <Admin
                layout={BQLayout}
                dataProvider={BQDataProvider}
                authData={bqAuthData}
                theme={bq_theme}
                logoutButton={null}
                refreshButton={null}
                ready={null}
                options={{
                    enableAdminQueries: false
                }}>

                <Resource
                    name="CognitoUsers"
                    list={isAdmin && CognitoUserComponent}
                    edit={isAdmin && CognitoUserComponent}
                    create={isAdmin && CognitoUserComponent}
                    options={{ label: 'Users' }}
                />

                <Resource
                    name="languages"
                    list={isSuperAdmin && LanguageComponent}
                    create={isSuperAdmin && LanguageComponent}
                    edit={isSuperAdmin && LanguageComponent}
                />

                <Resource
                    name="patients"
                    list={patientViewAllowed && userAllowed && PatientComponent}
                    create={patientViewAllowed && userAllowed && PatientComponent}
                    edit={patientViewAllowed && userAllowed && PatientComponent}
                    options={{ label: `${bqAuthData.isMarketTargetAudience ? 'Patients' : 'Subjects'}`, visible: false }}
                />

                <Resource
                    name="organizationalUnits"
                    list={isSuperAdmin && OrganizationalUnitComponent}
                    create={isSuperAdmin && OrganizationalUnitComponent}
                    edit={isSuperAdmin && OrganizationalUnitComponent}
                    options={{ label: 'Clinics' }}
                />

                <Resource
                    name="devices"
                    list={(isSuperAdmin || isViewer) && DeviceComponent}
                    create={isSuperAdmin && DeviceComponent}
                    edit={(isSuperAdmin || isViewer) && DeviceComponent}
                />

                <Resource name="progressions"
                    list={isSuperAdmin && ProgressionComponent}
                    create={isSuperAdmin && ProgressionComponent}
                    edit={isSuperAdmin && ProgressionComponent}
                    options={{ label: 'Progression' }}
                />

                <Resource name="equipments"
                    list={isSuperAdmin && EquipmentComponent}
                    create={isSuperAdmin && EquipmentComponent}
                    edit={isSuperAdmin && EquipmentComponent}
                    options={{ label: 'Equipment' }}
                />

                <Resource name="exercises"
                    list={isSuperAdmin && ExerciseComponent}
                    create={isSuperAdmin && ExerciseComponent}
                    edit={isSuperAdmin && ExerciseComponent}
                    options={{ label: 'Exercises' }}
                />

                <Resource name="exercisesCategorys"
                    list={isSuperAdmin && ExercisesCategoryComponent}
                    create={isSuperAdmin && ExercisesCategoryComponent}
                    edit={isSuperAdmin && ExercisesCategoryComponent}
                    options={{ label: 'Exercise Categories' }}
                />

                <Resource name="treatmentStages"
                    list={isSuperAdmin && TreatmentStageComponent}
                    create={isSuperAdmin && TreatmentStageComponent}
                    edit={isSuperAdmin && TreatmentStageComponent}
                    options={{ label: 'Exercise Stages' }}
                />

                <Resource
                    name="treatmentProtocols"
                    list={isSuperAdmin && TreatmentProtocolComponent}
                    create={isSuperAdmin && TreatmentProtocolComponent}
                    edit={isSuperAdmin && TreatmentProtocolComponent}
                    options={{ label: 'Treatment Protocols' }}
                />

                <Resource
                    name="auditTrails"
                    list={isAuditor && AuditTrailList}
                    create={null}
                    edit={null}
                    options={{ label: 'Audit Trail' }}
                />

                <Resource
                    name="auditTrailEventNames"
                    list={null}
                    create={null}
                    edit={null}
                    options={{ label: 'Audit Trail' }}
                />

                <Resource
                    name="questionnaires"
                    list={isSuperAdmin && QuestionnaireComponent}
                    create={isSuperAdmin && QuestionnaireComponent}
                    edit={isSuperAdmin && QuestionnaireComponent}
                    options={{ label: 'Questionnaires' }}
                />

                <Resource
                    name="patientAnalytics"
                    list={(isAdmin || isOperator || isViewer) && AnalyticsComponent}
                    create={null}
                    edit={null}
                    options={{ label: 'Dashboard' }}
                />

                <Resource
                    name="textMessages"
                    list={(isAdmin || isOperator) && TextMessageComponent}
                    create={(isAdmin || isOperator) && TextMessageComponent}
                    edit={(isAdmin || isOperator) && TextMessageComponent}
                    options={{ label: 'Text Messages' }}
                />

                <Resource
                    name="mobileDeviceUsers"
                    list={null}
                    create={null}
                    edit={null}
                    options={{ label: null }}
                />

                <Resource
                    name="mobileDevices"
                    list={(isSuperAdmin || isViewer) && MobileDeviceList}
                    create={null}
                    edit={null}
                    options={{ label: 'Mobile Devices' }}
                />
                {Object.keys(appSettings?.additionalResources).map(key => {
                    const { availableFor, name, url, label, params } = appSettings?.additionalResources?.[key]
                    const resourceAllowed = availableFor?.some(availableForRole => bqAuthData.role.includes(availableForRole))
                    return resourceAllowed ? <Resource
                        name={name}
                        list={<AdditionalResource {...{ name, url, params }} />}
                        options={{ label: label }}
                    /> : <></>
                })}

            </Admin>}
            {!userAllowed && <div id="not_authorized_label" style={{ position: 'absolute', top: '50%', left: '50%', height: '64px', zIndex: '99999' }}>The Admin panel is not available for given user role</div> || <></>}


            <div className={bqClasses.userInfoSection}>
                <div id="username_label" className={bqClasses.centerContent}>
                    {userName}
                </div>
            </div>
            <div id="logout_button" className={bqClasses.logoutButton} onClick={async () => {
                endVideoMeeting()
                await Auth.signOut()
                clearBQAuthData()
                checkAuthState();
            }}></div>
        </Provider>
}

const AppSettingsComponent = ({ dataProvider, setAppSettings }) => {
    const appSettings = useGetAppSettings(dataProvider)
    useEffect(() => {
        setAppSettings(appSettings)
    }, [appSettings])
    return null
}

const BQLayout = (props) => {
    const { isSuperAdmin, isViewer } = bqAuthData
    useBQRouter(props)
    return <Layout>
        <div style={{
            borderRadius: 5,
            backgroundColor: 'black',
            textColor: 'red !important',
            color: 'white',
            position: 'fixed',
            zIndex: 1300,
            width: 384,
            maxWidth: 192,
            bottom: 64,
            left: 10,
            verticalAlign: 'middle',
            alignItems: 'center',
            textAlign: 'left',
            display: (isSuperAdmin || isViewer) ? 'block' : 'none'
        }}>
            <div style={{ marginLeft: 16, paddingTop: 16, marginBottom: -20 }}>Language</div>
            <SimpleForm toolbar={null}>
                <LanguageSelection />
            </SimpleForm>
        </div>
        {props.children}
    </Layout>
}


export default App