import dayjs from "dayjs";
import { bqAuthData } from "./bq-auth";
import { useSelector } from 'react-redux';

export const limitText = (text, maxLength) => {
    if (!text) {
        return ''
    }
    maxLength = maxLength || 64;
    return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text
}

export const formDate = (date) => {
    const dateObject = date || new Date();
    if (isNaN(dateObject.getTime())) {
        return ''
    }
    return dayjs(dateObject).format('YYYY-MM-DD')
}

export const getDateTime = (date, hideTime) => {
    const dateObject = new Date(date);
    if (isNaN(dateObject.getTime())) {
        return ''
    }
    return dayjs(dateObject).format(`DD/MM/YYYY${hideTime ? '' : ' hh:mm A'}`)
}

export const replacePlaceholders = (text, formData, value) => {
    const matches = text && text.matchAll(/\[(.*?)\]/g);
    if (matches) {
        for (const match of matches) {
            const formValue = formData[`${match[1]}`]
            text = text.replace(match[0], match[1] === 'value' ? value : formValue)
        }
    }
    return text;
}

export const getTranslatedName = (item, langCode) => item?.nameTranslations?.find(lang => lang.code === langCode)?.text || item?.name || null

export const parseTime = (time, removeHours) => {
    if (!time) {
        return null
    }
    const hours = parseInt(time / 3600)
    const minutes = parseInt((time % 3600) / 60)
    const seconds = time % 60
    return `${!removeHours && `${timePadStart(hours)}:` || ''}${timePadStart(minutes)}:${timePadStart(seconds)}`
}

export const timePadStart = (value) => {
    return value.toString().padStart(2, '0')
}

export const fixValue = (value, pow) => {
    const muliplyer = Math.pow(10, pow)
    return (parseInt(value * muliplyer) / muliplyer)
}
export const decapitalize = (string) => string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() || ''

export const lineBreaks = (text, defaultValue) => ((text || defaultValue)?.replace(/(?:\r\n|\r|\n)/g, '<br/>') || '').split('<br/>').map(item => <span>{item}<br /></span>)

export const getLabel = (child) => {
    const { source, label } = child?.props || {};
    if (label) {
        return label;
    }
    const language = useSelector(state => state.langSelected.language);
    const toReturn = (source?.length ? source.slice(0, 1).toUpperCase() + source.slice(1) : source)
        ?.split(/(?=[A-Z])/)?.join(' ')
    if (child?.props?.translateHeader) {
        return toReturn + (language?.code?.toLowerCase() === 'en' ? '' : ` (${language?.name})`);
    }
    return toReturn
}

export const getValueByPath = (obj, path) => path
    ?.replace(/\[(\w+)\]/g, '.$1')
    ?.split('.')
    ?.filter(t => t.length > 0)
    ?.reduce((prev, curr) => prev ? prev[curr] : undefined, obj);

export const getEMFTimeString = (app_emf_time, device_emf_time) => {
    const { isSuperAdmin, isViewer } = bqAuthData
    const showMaxData = isSuperAdmin || isViewer

    let emfDuration = `${parseTime(app_emf_time, true)}`
    if (device_emf_time < app_emf_time - 60) {
        if (showMaxData) {
            emfDuration = lineBreaks(`${emfDuration}\n(${parseTime(device_emf_time, true) || 'No'} device time)`)
        } else {
            emfDuration = `${emfDuration} *`
        }
    }
    return emfDuration
}

export const sortDataGrid = (data, field, order) => [...data].sort((a, b) => {
    const valA = a[field]
    const valB = b[field]
    let sortVal = 0
    switch (typeof valA) {
        case 'number':
            sortVal = valA - valB
            break;
        case 'boolean':
            sortVal = valA - !!valB
            break;
        case 'undefined':
            sortVal = (typeof valB === 'undefined' ? 0 : -1)
            break;
        default:
            sortVal = valA?.localeCompare(valB)
            break;
    }
    if (order === 'DESC') {
        sortVal *= -1
    }
    return sortVal
})

export const isNullOrUndefined = (value) => value === null || value === undefined

export const isNullOrEmpty = (value) => value === null || value === undefined || value === '' || (typeof value === 'object' && !Object.keys(value).length)