/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    TextField,
    Create,
    Edit,
    DataProviderContext
} from 'react-admin';

import { getDuplicates, validate, validateDuplicates, validateString } from '../utils/validations';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQSimpleForm, FunctionField, TranslationInput } from './Generic/bq-form-components';
import { getLists } from './Generic/TreatmentProtocolExerciseData';
import { LinearProgress } from '@material-ui/core';
import BQIterator from './Generic/BQIterator';
import { BQModelList, BQSection, BQSideBySide, BQToolbar } from './Generic/BQUI';
import { getTranslatedName, limitText } from '../utils/textUtils';
import { BQDropDown } from './Generic/bq-input';
import { prepareFormData } from '../utils/transforms';
import BQCRUDBuilder from './Generic/BQCRUDComponent';

const exercisesCategoryTransform = async (data, setDuplicates, cache) => {
    const duplicates = await getDuplicates('ExercisesCategory', data, ['name']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    data.exercises = data.exercises && [...new Set(data.exercises.filter(exerciseId => cache.exercisesCache.some(cachedExercise => cachedExercise.id === exerciseId)))] || []
    return prepareFormData(data)
}

const ExercisesCategoryList = (props) => {
    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'

    return <BQModelList>
        <TextField source="name" />
        {isEnglish ? <FunctionField source="description" value={v => limitText(v)} /> : <FunctionField source="nameTranslations" label={`Name - ${langName}`} value={v => v?.find(t => t.code === langCode)?.text} />}
    </BQModelList>
}

const ExercisesCategoryEditor = (props) => {
    const { isLoadingLists, cache } = props;
    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'Category',
        duplicates: props.duplicates
    }

    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'

    return (
        <BQSimpleForm toolbar={<BQToolbar type="Exercise Category" />}>
            <BQSideBySide>
                <BQSection title="Exercise Category Info" language={props.language}>
                    <TranslationInput source="name" validate={validateString} duplicates={duplicatesCheckData} />
                    <TranslationInput multiline source="description" />
                </BQSection>

                <BQSection title="Exercises in Category" language={props.language}>
                    {isLoadingLists || !cache ? <LinearProgress /> :
                        <BQIterator source="exercises">
                            <BQDropDown
                                noLabel
                                validate={validate}
                                minWidth={360}
                                choices={cache.exercisesCache}
                                optionText={(item) => getTranslatedName(item, langCode)}
                            />
                        </BQIterator>}
                </BQSection>

            </BQSideBySide>

        </BQSimpleForm>
    )
}


const ExercisesCategoryMutation = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();


    const dataProvider = useContext(DataProviderContext);
    const [state, setState] = useState({ isLoadingDictionaries: true });
    useEffect(() => {
        getLists(dataProvider, true).then(data => setState({
            isLoadingDictionaries: false,
            cache: data
        }))
    }, [dataProvider]);

    const stateRef = useRef()
    stateRef.current = state

    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => exercisesCategoryTransform(data, setDuplicates, stateRef.current.cache) }

    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <ExercisesCategoryEditor {...props} redirect={bqCreateRedirection} {...state} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <ExercisesCategoryEditor {...props} redirect={bqEditRedirection} {...state} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const ExercisesCategoryCreate = (props) => ExercisesCategoryMutation(true, props)

const ExercisesCategoryEdit = (props) => ExercisesCategoryMutation(false, props)

export default BQCRUDBuilder({
    Create: ExercisesCategoryCreate,
    Edit: ExercisesCategoryEdit,
    List: ExercisesCategoryList
})