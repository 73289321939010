/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    Edit,
    BooleanField,
    useNotify,
    useRedirect,
    useRecordContext,
    useUpdate,
    useResourceContext,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import { bqAuthData } from '../utils/bq-auth';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { getDateTime } from '../utils/textUtils';
import { getDuplicates, validateDuplicates, validateEmail, validatePhoneNumber, validateUsename } from '../utils/validations';
import { BQAutoCompleteArrayInput, BQLabelWithInput, BQSimpleForm, FunctionField } from './Generic/bq-form-components';
import { BQInput } from './Generic/bq-input';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { prepareFormData } from '../utils/transforms';
import { Button, CircularProgress } from '@material-ui/core';
import { tempPasswordGenerated } from '../redux/dispatchers/tempPasswordActions';
import { OUSelectInput } from './organizationUnitSelector.component';
import BQCRUDBuilder from './Generic/BQCRUDComponent';

const validateRoles = (roles, allowedRoleCombinations) => {
    if (!roles || roles.length === 0) {
        return 'Required'
    }
    if (!checkAllowedRoleCombination(roles, allowedRoleCombinations)) {
        return 'You cannot create a user with the combination of these roles'
    }
    return null
}

const checkAllowedRoleCombination = (roles, allowedRoleCombinations) => {
    if (!roles || roles.length === 1) {
        return true
    }
    return roles.some(item => item === 'Super Administrator') ||
        allowedRoleCombinations.some(combination =>
            !roles.some(role => !combination.some(comb => role === comb))
        )
}

const cognitoUserTransform = async (data, setDuplicates) => {
    if (setDuplicates) {
        const duplicates = await getDuplicates('CognitoUser', data, ['email']);
        setDuplicates(duplicates)
        if (duplicates?.length > 0) {
            return false
        }
    }

    return prepareCogntioUserFormData(data)
};

const prepareCogntioUserFormData = (data) => {
    const toReturn = prepareFormData(data)
    if (typeof toReturn?.role === 'object') {
        toReturn.role = JSON.stringify(toReturn.role)
    }
    delete toReturn.organizationalUnitName
    delete toReturn.tempPassword
    delete toReturn.deletedAt
    return toReturn
}

const CognitoUserList = ({ appSettings }) => {
    const { roles } = appSettings || {}
    return <BQModelList
        disableDelete={() => {
            const record = useRecordContext()
            return !record?.isActive
        }}
        deleteIconLabel="Deactivate user"
        perPage="25"
        nameField="email">
        <TextField source="email" />
        <FunctionField
            label="Role"
            source="role"
            value={(val) => {
                let rolesArray = val
                try {
                    rolesArray = JSON.parse(val)
                }
                catch {
                    return ''
                }
                const roleString = rolesArray?.map(role => roles?.find(r => r.id === role)?.name).reduce((acc, role) => `${acc}, ${role}`)
                return roleString
            }} />
        <TextField source="organizationalUnitName" label="Clinic" />
        <FunctionField label="Created" source="createDate" value={(val) => getDateTime(val)} />
        <FunctionField label="Last updated" source="lastModifiedDate" value={(val) => getDateTime(val)} />
        <BooleanField label="Activation status" source="isActive" />
    </BQModelList>
}

const ResetPasswordButton = () => {
    const record = useRecordContext()
    const [resetPasswordResponse, setResetPasswordResponse] = React.useState()

    const [resetPassword, { isLoading }] = useUpdate('cognitoUsers',
        {
            id: record.id, data: { ...prepareCogntioUserFormData(record), customAction: 'resendPassword' }
        },
        {
            onSuccess: (data) => {
                setResetPasswordResponse(data)
            }
        });

    const [resetButtonState, setResetButtonState] = React.useState(true)
    const notify = useNotify()
    const dispatch = useDispatch()


    React.useEffect(() => {
        if (resetPasswordResponse) {
            if (resetPasswordResponse?.tempPassword) {
                dispatch(tempPasswordGenerated(resetPasswordResponse.email, resetPasswordResponse.tempPassword))
                setResetButtonState(false)
                notify('An email was sent to the user, please ask the user to follow the instructions in the email',
                    { autoHideDuration: 6000, type: 'success' })
            } else {
                notify('Error sending email to the user, please try again later',
                    { autoHideDuration: 6000, type: 'success' })
            }
        }
    }, [isLoading])

    return resetButtonState ?
        <Button label="Activate / Reset password"
            id="reset_password_button"
            className="MuiButton-contained"
            style={{ minWidth: '320px', boxShadow: 'none' }}
            onClick={async () => {
                setResetPasswordResponse(null)
                resetPassword()
            }}
            disabled={isLoading}
        >{!!isLoading && <CircularProgress style={{ width: '16px', height: '16px', marginTop: '2px', marginRight: '16px' }} />}<span>Activate / Reset password</span></Button>
        :
        <></>
};

const fieldNamesToWatch = ['role']

const getValidRoles = (roles, userRoles) => {
    const availableRoles = userRoles?.reduce((acc, userRole) => {
        roles?.find(role => role.id === userRole)?.availableRoles?.forEach(availableRole => {
            if (availableRole === '*') {
                roles.forEach(role => acc[role.id] = true)
            } else {
                acc[availableRole] = true
            }
        })
        return acc
    }, [])
    return roles?.filter(role => availableRoles[role.id])
}

const CognitoUserEditor = (props) => {
    const { roles, allowedRoleCombinations } = props?.appSettings || {}
    const { role: userRoles } = bqAuthData
    const validRoles = getValidRoles(roles, userRoles)

    const record = useRecordContext()

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'A user',
        duplicates: props.duplicates
    }

    const [formWatch, setFormWatch] = React.useState({})
    const [bqForm, setBQForm] = React.useState({})
    React.useEffect(() => {
        const watchObject = {}
        fieldNamesToWatch.forEach((name, i) => watchObject[name] = formWatch[i])
        setBQForm(watchObject)
    }, [formWatch])

    const { role } = bqForm
    let displayPhoneNumber = Array.isArray(role) && role?.some(role => role?.match(/Super administrator/i))

    return !!roles && <BQSimpleForm
        prepareForm={({ formData, setValue }) => {
            typeof formData?.role !== 'object' && setValue('role', formData.role ? JSON.parse(formData.role) : [], { shouldTouch: true, shouldDirty: false })
        }}
        setWatch={{
            action: setFormWatch,
            name: fieldNamesToWatch
        }}
        toolbar={<BQToolbar type="User" nameField="email" />}>
        <BQSection title="User Info">
            <BQInput source="email" validate={validateEmail} duplicates={duplicatesCheckData} readOnly={record.id} />
            <BQInput label="Username" source="bqUsername" validate={validateUsename} placeholder="First name.Last name (or initials)" />
            {
                !!displayPhoneNumber && <BQInput type="phoneNumber" label="Phone number" source="phoneNumber" validate={validatePhoneNumber} placeholder="Phone number with country code" />
            }
            <BQLabelWithInput
                idClassName="roleList">
                Role(s) *
                <BQAutoCompleteArrayInput source="role" choices={validRoles} variant="outlined" validate={values => validateRoles(values, allowedRoleCombinations)} title="" />
            </BQLabelWithInput>

            <OUSelectInput />
        </BQSection>
        {record.id &&
            <>
                <br />
                <br />
                <ResetPasswordButton />
            </>
        }
    </BQSimpleForm>
};

const CognitoUserMutate = (isCreate, props) => {
    const resourceName = useResourceContext()
    const dispatch = useDispatch()
    const redirect = useRedirect()
    const { tempPassword, appSettings } = props
    const [duplicates, setDuplicates] = React.useState();

    const editorProps = { ...props, ...editorGlobalProps(), transform: (data) => cognitoUserTransform(data, setDuplicates) }
    if (isCreate) {
        editorProps.record = { isActive: true }
    }

    return (
        <div>
            {isCreate ?
                (<Create {...editorProps} mutationOptions={{
                    onSuccess: data => {
                        if (data?.tempPassword) {
                            dispatch(tempPasswordGenerated(data.email, data.tempPassword))
                        }
                        redirect(`/${resourceName}`)
                    }
                }} >
                    <CognitoUserEditor tempPassword={tempPassword} redirect={bqCreateRedirection} duplicates={duplicates} appSettings={appSettings} />
                </Create>)
                :
                (<Edit {...editorProps} >
                    <CognitoUserEditor tempPassword={tempPassword} redirect={bqEditRedirection} duplicates={duplicates} appSettings={appSettings} />
                </Edit>)
            }
        </div >
    )
}

const CognitoUserCreate = (props) => CognitoUserMutate(true, props)

const CognitoUserEdit = (props) => CognitoUserMutate(false, props)

export default BQCRUDBuilder({
    Create: CognitoUserCreate,
    Edit: CognitoUserEdit,
    List: CognitoUserList
})