import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DraggableDialog from "./ConfirmationDialog"

import { tempPasswordUnhide, tempPasswordCopied, tempPasswordClear } from '../../redux/dispatchers/tempPasswordActions';
import { useBQStyles } from '../../themes';
import { getFromCache } from '../../utils/globals';

const TempPasswordDialog = () => {
    const tempPassword = useSelector(state => state.tempPasswordObject.tempPassword);
    const classes = getFromCache('bqClasses');
    const dispatch = useDispatch()
    return <DraggableDialog
        disableCancel={true}
        confirmText='Close'
        open={tempPassword?.password}
        title={`Temporary Password ${tempPassword?.username ? `for ${tempPassword.username}` : ''}`}
        onConfirm={() => dispatch(tempPasswordClear())}
        content={
            <div>
                Temporary password has been sent to the user. You can view it here, but note that once you click “close” you will not be able to re-access it.
                <table>
                    <tr>
                        <td style={{ minWidth: '250px' }}>
                            <p id="lbl_temp_password">Temporary Password: {tempPassword?.hide ? '********' : tempPassword?.password}</p>
                        </td>
                        <td>
                            <div
                                id="btn_temp_password_eye"
                                className={tempPassword?.hide ? classes.hiddenEyeButton : classes.eyeButton}
                                onMouseDown={() => dispatch(tempPasswordUnhide(true))}
                                onMouseUp={() => dispatch(tempPasswordUnhide(false))}
                                onMouseLeave={() => dispatch(tempPasswordUnhide(false))}
                            ></div>
                        </td>
                        <td>
                            <div
                                id="btn_temp_password_copy"
                                className={classes.copyButton}
                                onClick={() => {
                                    navigator.clipboard.writeText(tempPassword.password)
                                    dispatch(tempPasswordCopied())
                                }}
                            >
                            </div>
                        </td>
                        <td
                            id="lbl_temp_password_copied"
                            className={classes.bqInputLabel}
                            style={{ color: 'green', fontWeight: 'bold' }}
                        >
                            {!!tempPassword?.copied && 'Copied to clipboard'}
                        </td>
                    </tr>
                </table>
            </div>
        } />
}

export default TempPasswordDialog