import {
    TEMP_PASSWORD_GENERATED,
    TEMP_PASSWORD_UNHIDE,
    TEMP_PASSWORD_COPIED,
    TEMP_PASSWORD_CLEAR
} from '../actions';
import { bqState } from '../bqState';

export default (state, { type, payload }) => {
    state = state || bqState
    switch (type) {
        case TEMP_PASSWORD_GENERATED:
            return {
                ...state, tempPassword: {
                    username: payload.username,
                    password: payload.password,
                    hide: true
                }
            };

        case TEMP_PASSWORD_UNHIDE:
            return {
                ...state, tempPassword: {
                    ...(state.tempPassword || {}),
                    hide: !payload.state
                }
            };
        case TEMP_PASSWORD_COPIED:
            return {
                ...state, tempPassword: {
                    ...(state.tempPassword || {}),
                    copied: true
                }
            };
        case TEMP_PASSWORD_CLEAR:
            return {
                ...state, tempPassword: null
            };
    }
    return state
}