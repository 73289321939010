import * as Auth from '@aws-amplify/auth';
import React from 'react';
import { clearBQAuthData } from '../../utils/bq-auth';
import styles from './auth.module.css'

const PASSWORD_REQUIREMENTS_ERROR = 'Password should be at least 8 characters long, containing an uppercase letter, a lowercase letter, a digit and a special character (?!#@ etc.).'

const AuthStateOptions = {
    UNSET: {},
    SIGN_IN: { title: 'Login to your account', button: 'Sign in' },
    NEW_PASSWORD_REQUIRED: { title: 'New password required', description: 'Please change your initial password', button: 'Create new password' },
    SMS_MFA_REQUIRED: { title: 'Code from SMS', description: 'Please enter the code we sent to ${PhoneNumber}', button: 'Confirm' },
    TOTP_MFA_REQUIRED: { title: 'Code from Authenticator', description: 'Please enter the code from your authenticator app', button: 'Confirm' },
    FORGOT_PASSWORD: { title: 'Password Reset', description: 'To reset your password, enter the email address', button: 'Reset Password' },
    PASSWORD_TOO_OLD: { title: 'Your password has expired', description: 'To reset your password, enter the email address', button: 'Reset Password' },
    RESET_PASSWORD: { title: 'Set a new password', description: `The confirmation code has been sent to [email] or as a text message to the tablet’s Messages app`, button: 'Save' },
    AUTHENTICATED: { title: 'Authenticated', button: 'Authonticated' }
}

const getPasswordItemStyle = (value) => value ? styles.verifiedPasswordCeriteria : styles.nonVerifiedPasswordCeriteria

export class BQAuthenticator extends React.Component {
    constructor(props) {
        super(props);
        this.missingFieldsMessage = 'Please check missing fields';
        this.state = {
            authStatus: AuthStateOptions.UNSET,
        }
        const reloadTime = sessionStorage.getItem('reload')
        const isReload = reloadTime &&
            (location.href.indexOf('http://localhost:3000/') !== -1 || new Date().getTime() - reloadTime < 5000)

        if (isReload) {
            this.checkAuthState();
        } else {
            clearBQAuthData()
            Auth.signOut().then(() => {
                this.checkAuthState();
            })
        }
    }

    setAuthState(authState, resetAll) {
        if (resetAll) {
            this.setState({
                username: '',
                password: '',
                passwordConfirmation: '',
                resetCode: '',
                currentUser: null,
                isWaiting: false,
                revealPassword: false,
                authStatus: authState,
                errorFields: []
            });
        }
        this.setState({
            authStatus: authState,
            password: '',
            errorMessage: null,
            isWaiting: false,
            resetCode: '',
            passwordConfirmation: '',
        });
        setTimeout(() => document.getElementsByTagName('input')?.[0]?.focus(), 0)
    }

    setError(errorMessage, errorObject) {
        console.error(errorMessage);
        errorObject && console.log('Error object:', JSON.stringify(errorObject, null, 2))
        if (errorObject?.code === 'UserNotFoundException') {
            errorMessage = 'Username not found. Please contact your clinic admin.'
            errorObject = null
        }
        this.setState({
            errorMessage: `${errorMessage}${errorObject?.message && (':\n' + errorObject.message.replace(/:\s/ig, ':\n')) || ''}`
        });
    }

    checkAuthState() {
        Auth.getCurrentUser().then(() => {
            this.setAuthState(AuthStateOptions.AUTHENTICATED)
        }).catch(() => {
            clearBQAuthData();
            this.setAuthState(AuthStateOptions.SIGN_IN)
        });
    }

    handleAuthenticationEvent(response, tmpPassword) {
        switch (response?.nextStep?.signInStep) {
            case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
                this.setState({ currentUser: response, oldPassword: tmpPassword })
                this.setAuthState(AuthStateOptions.NEW_PASSWORD_REQUIRED)
                break;
            case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
                this.setState({ currentUser: response })
                this.setAuthState(AuthStateOptions.SMS_MFA_REQUIRED)
                break;
            case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
                this.setState({ currentUser: response })
                this.setAuthState(AuthStateOptions.TOTP_MFA_REQUIRED)
                break;
            default:
                if (response) {
                    this.setAuthState(AuthStateOptions.AUTHENTICATED, true)
                    window.history.pushState(null, null, '/')
                } else {
                    this.setError('Invalid email or password')
                }
                break;
        }
    }

    isWaiting(state) {
        this.setState({ isWaiting: state })
    }

    conformsToPasswordPolicy(str) {
        return !!str?.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!-/:-@\\[-`{-~])[A-Za-z0-9!-/:-@\\[-`{-~\s]{8,256}$/)
    }

    async signIn() {
        const { username, password } = this.state;
        if (!username || !username.trim() || !password || !password.trim()) {
            this.setError(this.missingFieldsMessage)
            this.setState({ password: '', errorFields: ['username', 'password'] })
            return;
        }
        const tmpPassword = password;
        this.setState({ password: '', errorFields: ['username', 'password'] })
        try {
            this.isWaiting(true)
            const user = await Auth.signIn({
                username: username.trim(),
                password: tmpPassword
            });
            this.handleAuthenticationEvent(user, tmpPassword)
        } catch (error) {
            console.log(error, error.message)
            if (error.message.indexOf('PASSWORD_EXPIRED') !== -1) {
                this.setAuthState(AuthStateOptions.PASSWORD_TOO_OLD)
            } else if (error.name === 'PasswordResetRequiredException') {
                this.setAuthState(AuthStateOptions.RESET_PASSWORD)
            } else {
                if (error?.message?.match(/disabled|expired/i)) {
                    this.setError('Your user has not been activated. Please contact BrainQ support')
                } else if (error?.message?.match(/Password attempts exceeded/i)) {
                    this.setError('Too many failed login attempts. Try again in a few minutes.')
                } else {
                    this.setError('Invalid email or password.')
                }
            }
        }
        this.isWaiting(false)
    }

    async confirmMFA() {
        const { mfa, currentUser } = this.state;
        if (!mfa?.trim()) {
            this.setError(this.missingFieldsMessage)
            this.setState({ mfa: '', errorFields: ['mfa'] })
            return;
        }
        const tmpMFA = mfa;
        this.setState({ mfa: '', errorFields: ['mfa'] })
        try {
            this.isWaiting(true)
            const user = await Auth.confirmSignIn({
                challengeResponse: tmpMFA
            })
            if (user) {
                this.setAuthState(AuthStateOptions.AUTHENTICATED, true)
                window.history.pushState(null, null, '/')
            } else {
                this.setError('Invalid verification code provided. Please try again.')
            }
        } catch (error) {
            console.log(JSON.stringify(error), error.message)
            if (error.message.indexOf('PASSWORD_EXPIRED') !== -1) {
                this.setAuthState(AuthStateOptions.PASSWORD_TOO_OLD)
            } else {
                switch (error.name) {
                    case 'CodeMismatchException':
                        this.setAuthState(AuthStateOptions.SMS_MFA_REQUIRED)
                        this.setError('Invalid verification code provided. Please try again.')
                        break;
                    default:
                        if (error?.message?.match(/disabled/i)) {
                            this.setError('Your user has not been activated. Please contact BrainQ support')
                        } else if (error?.message?.match(/expired/)) {
                            this.setError('Your verification code has expired. Please login again')
                        } else {
                            this.setError('Invalid verification code provided. Please try again.', 'mfa')
                        }
                        break;
                }
            }
        }
        this.isWaiting(false)
    }

    async completeNewPassword() {
        const { currentUser, password, passwordConfirmation } = this.state;
        if (!password || !password.trim() || !passwordConfirmation || !passwordConfirmation.trim()) {
            this.setError(this.missingFieldsMessage)
            this.setState({ password: '', errorFields: ['password', 'passwordConfirmation'] })
            return;
        }
        const tmpPassword = password;
        const tmpPasswordConfirmation = passwordConfirmation;
        this.setState({ password: '' })
        this.setState({ passwordConfirmation: '' })
        if (tmpPassword !== tmpPasswordConfirmation) {
            this.setError('Passwords don\'t match')
            this.setState({ password: '', errorFields: ['password', 'passwordConfirmation'] })
            return;
        }
        if (!this.conformsToPasswordPolicy(tmpPassword)) {
            this.setError(PASSWORD_REQUIREMENTS_ERROR)
            this.setState({ password: '', errorFields: ['password', 'passwordConfirmation'] })
            return;
        }
        try {
            this.isWaiting(true)
            const user = await Auth.confirmSignIn({
                challengeResponse: tmpPassword
            });
            if (user) {
                this.handleAuthenticationEvent(user, tmpPassword)
            } else {
                this.setError('Could not set a new password', user)
                this.setState({ password: '', errorFields: ['password', 'passwordConfirmation'] })
            }
        } catch (error) {
            this.setError('Could not set a new password', error)
            this.setState({ password: '', errorFields: ['password', 'passwordConfirmation'] })
        }
        this.isWaiting(false)
    }

    async forgotPassword() {
        const { username } = this.state;
        if (!username || !username.trim()) {
            this.setError(this.missingFieldsMessage)
            return;
        }
        try {
            this.isWaiting(true)
            console.log(await Auth.resetPassword({ username: username.trim() }))
            this.setAuthState(AuthStateOptions.RESET_PASSWORD)
        } catch (error) {
            console.log(error)
            if (error.message.indexOf('User password cannot be reset in the current state') === 0) {
                this.setError('Your user has not been activated. Please contact BrainQ support', null)
            } else if (error.message.indexOf('Attempt limit exceeded, please try after some time') === 0) {
                this.setError(error.message, null)
            } else {
                this.setAuthState(AuthStateOptions.RESET_PASSWORD)
            }
        }
        this.isWaiting(false)
    }

    async resetPassword() {
        const { username, resetCode, password, passwordConfirmation } = this.state;
        if (!resetCode || !password || !password.trim() || !passwordConfirmation || !passwordConfirmation.trim()) {
            this.setError(this.missingFieldsMessage)
            this.setState({ password: '', errorFields: [!resetCode && 'resetCode', 'password', 'passwordConfirmation'] })
            return;
        }
        const tmpPassword = password;
        const tmpPasswordConfirmation = passwordConfirmation;
        this.setState({ password: '' })
        this.setState({ passwordConfirmation: '' })
        if (tmpPassword !== tmpPasswordConfirmation) {
            this.setState({ password: '', errorFields: ['password', 'passwordConfirmation'] })
            this.setError('passwords don\'t match')
            return;
        }
        if (!this.conformsToPasswordPolicy(tmpPassword)) {
            this.setError(PASSWORD_REQUIREMENTS_ERROR)
            this.setState({ password: '', errorFields: ['password', 'passwordConfirmation'] })
            return;
        }
        try {
            this.isWaiting(true)
            await Auth.confirmResetPassword({
                username: username.trim(),
                newPassword: tmpPassword,
                confirmationCode: resetCode.trim()
            });
            this.setAuthState(AuthStateOptions.SIGN_IN)
        } catch (error) {
            switch (error.name) {
                case 'ExpiredCodeException':
                    this.setError('Your code has expired. Please repeat the "Forgot password" process or contact your clinic admin.')
                    break;
                default:
                    this.setError('Password reset request error', error)
                    break;
            }
        }
        this.setState({ resetCode: '' })
        this.isWaiting(false)
    }

    createInput(inputName, placeholder, leftIcon, rightIcon) {
        const { revealPassword, errorMessage } = this.state;
        const fieldFromState = this.state[inputName];
        const isErrorField = this.state.errorFields?.indexOf(inputName) !== -1
        const isPasswordField = inputName.toLowerCase().indexOf('password') !== -1;
        const className = [styles.inputContainer, errorMessage && isErrorField ? styles.error : null].join(' ')
        return <table className={className}>
            <tr>
                <td className={styles.inputIcon}>
                    <div id={`${inputName}_icon`} className={leftIcon}>
                    </div>
                </td>
                <td className={styles.inputField}>
                    <input id={`${inputName}_input`} type={isPasswordField && revealPassword !== inputName ? 'password' : null} value={fieldFromState} placeholder={placeholder}
                        autoComplete='off'
                        onChange={(e) => {
                            const toChange = {}
                            let value = e.target.value
                            if (!isPasswordField) {
                                value = value.trim()
                            }
                            toChange[inputName] = value
                            this.setState(toChange)
                        }}
                        onKeyPress={e => !!e.code.match(/enter/i) && this.authAction()} />
                </td>
                {rightIcon &&
                    <td className={styles.inputIcon}>
                        <div id={`${inputName}_right_icon`} className={rightIcon}
                            onMouseDown={() => isPasswordField && this.setState({ revealPassword: inputName })}
                            onMouseUp={() => this.setState({ revealPassword: false })}
                        >
                        </div>
                    </td>}
            </tr>
        </table>
    }

    titleComponent() {
        const { authStatus } = this.state;
        return <div id="auth_title" className={styles.loginPageTitle}>{authStatus.title}</div>
    }

    descriptionComponent() {
        const { username, authStatus, currentUser } = this.state;
        return <div id="description_label" className={styles.loginPageDescription}>{authStatus?.description?.replace('[email]', username)?.replace('${PhoneNumber}', currentUser?.nextStep?.codeDeliveryDetails?.destination)}</div>
    }

    usernameComponent() {
        const { authStatus } = this.state;
        switch (authStatus) {
            case AuthStateOptions.SIGN_IN:
            case AuthStateOptions.FORGOT_PASSWORD:
            case AuthStateOptions.PASSWORD_TOO_OLD:
                return this.createInput('username', 'Email', styles.emailIcon)
        }
    }

    passwordPolicyComponent() {
        const { authStatus } = this.state;
        switch (authStatus) {
            case AuthStateOptions.NEW_PASSWORD_REQUIRED:
            case AuthStateOptions.RESET_PASSWORD:
                const { password } = this.state
                return <p className={styles.loginPageDescription}>
                    Password should be at least&nbsp;
                    <span className={getPasswordItemStyle(password.match(/[\w\W]{8,}/))}>
                        8 characters long
                    </span>
                    , containing&nbsp;
                    <span className={getPasswordItemStyle(password.match(/[A-Z]/))}>
                        an uppercase letter
                    </span>
                    ,&nbsp;
                    <span className={getPasswordItemStyle(password.match(/[a-z]/))}>a lowercase letter</span>
                    ,&nbsp;
                    <span className={getPasswordItemStyle(password.match(/\d/))}>a digit</span>
                    &nbsp;and&nbsp;
                    <span className={getPasswordItemStyle(password.match(/[!-/:-@\\[-`{-~]/))}>a special character</span>
                    <span> (?!#@ etc.).</span>
                </p>
        }
    }

    passwordResetCodeComponent() {
        const { authStatus } = this.state;
        switch (authStatus) {
            case AuthStateOptions.RESET_PASSWORD:
                return this.createInput('resetCode', 'Confirmation code', styles.emailIcon)
        }
    }

    passwordComponent() {
        const { authStatus } = this.state;
        switch (authStatus) {
            case AuthStateOptions.SIGN_IN:
            case AuthStateOptions.RESET_PASSWORD:
            case AuthStateOptions.NEW_PASSWORD_REQUIRED:
                return this.createInput('password', 'Password', styles.passwordIcon, styles.passwordRevealIcon)
        }
    }

    passwordConfirmationComponent() {
        const { authStatus } = this.state;
        switch (authStatus) {
            case AuthStateOptions.NEW_PASSWORD_REQUIRED:
            case AuthStateOptions.RESET_PASSWORD:
                return this.createInput('passwordConfirmation', 'Confirm Password', styles.passwordIcon, styles.passwordRevealIcon)
        }
    }

    mfaComponent() {
        const { authStatus } = this.state;
        switch (authStatus) {
            case AuthStateOptions.SMS_MFA_REQUIRED:
                return this.createInput('mfa', 'Please enter the code from SMS', styles.passwordIcon)
            case AuthStateOptions.TOTP_MFA_REQUIRED:
                return this.createInput('mfa', 'Please enter the code from the authenticator app', styles.passwordIcon)
        }
    }

    resetPasswordComponent() {
        const { authStatus } = this.state;
        if (authStatus === AuthStateOptions.SIGN_IN) {
            return (<div><a id="forgot_password_button" className={styles.forgotPasswordLink} onClick={() => this.setAuthState(AuthStateOptions.FORGOT_PASSWORD)}>Forgot Password</a></div>)
        }
    }

    backToSignInComponent() {
        const { authStatus } = this.state;
        if (authStatus !== AuthStateOptions.SIGN_IN) {
            return (<div id="back_button" className={styles.backToLogin} onClick={() => this.setAuthState(AuthStateOptions.SIGN_IN)}>Back to login</div>)
        }
    }

    errorMessageComponent() {
        const { errorMessage } = this.state;
        if (errorMessage) {
            return <div id="error_label" className={styles.errorMessage}>{errorMessage}</div>
        }

    }

    waitIndicatorComponent() {
        const { isWaiting } = this.state;
        if (isWaiting) {
            return (
                <div id="waiting_indicator" className={styles.waitCover}>
                    <div className={styles.dotsWrapper}>
                        <span className={[styles.dot, styles.dot1].join(' ')}></span>
                        <span className={[styles.dot, styles.dot2].join(' ')}></span>
                        <span className={[styles.dot, styles.dot3].join(' ')}></span>
                    </div>
                </div>)
        }
    }

    authAction() {
        const { authStatus } = this.state;
        switch (authStatus) {
            case AuthStateOptions.SIGN_IN:
                this.signIn();
                break;
            case AuthStateOptions.NEW_PASSWORD_REQUIRED:
                this.completeNewPassword();
                break;
            case AuthStateOptions.FORGOT_PASSWORD:
            case AuthStateOptions.PASSWORD_TOO_OLD:
                this.forgotPassword();
                break;
            case AuthStateOptions.RESET_PASSWORD:
                this.resetPassword();
                break;
            case AuthStateOptions.SMS_MFA_REQUIRED:
            case AuthStateOptions.TOTP_MFA_REQUIRED:
                this.confirmMFA();
                break;
        }
    }

    authFormComponent() {
        const { authStatus } = this.state;
        return (
            <table className={styles.loginContainer}>
                <tr>
                    <td className={styles.loginImageSection}>
                        <div className={styles.loginBackgroundImage}>
                            <div className={styles.brainqLogoWhite}></div>
                        </div>
                    </td>
                    <td className={styles.loginFormSection}>
                        <div className={styles.loginFormContainer}>
                            {this.backToSignInComponent()}
                            <div className={styles.loginTitleSection}>
                                {this.titleComponent()}
                                {this.descriptionComponent()}
                            </div>
                            {this.usernameComponent()}
                            {this.passwordPolicyComponent()}
                            {this.passwordComponent()}
                            {this.passwordConfirmationComponent()}
                            {this.passwordResetCodeComponent()}
                            {this.mfaComponent()}
                            <div className={styles.belowInputsSection}>
                                {this.resetPasswordComponent()}
                                {this.errorMessageComponent()}
                            </div>
                            <button id="auth_button" className={styles.loginPageButton} onClick={() => {
                                this.authAction();
                            }}>{authStatus.button}</button>
                            {this.waitIndicatorComponent()}
                        </div>
                    </td>
                </tr>
            </table>
        )
    }

    renderMainComponent() {
        return React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                    checkAuthState: () => this.checkAuthState(),
                });
            }
            return child;
        })
    }

    render() {
        const { authStatus } = this.state;
        switch (authStatus) {
            case AuthStateOptions.UNSET:
                return <></>
            case AuthStateOptions.AUTHENTICATED:
                return this.renderMainComponent()
            default:
                return this.authFormComponent();
        }
    }
}