import { useEffect, useContext, useState } from "react";
import { DataProviderContext } from "react-admin";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const useAvailableResources = () => {
    const resources = useSelector(state => state.admin.resources);
    const router = useSelector(state => state.router);

    useEffect(() => {
        const currentResource = router.location.pathname.match(/\/(.*?)($|\/|\?)/)[1]
        const resourcesArray = Object.keys(resources).map(key => resources[key])
        if (!resourcesArray.length) {
            return
        }
        const availableResources = resourcesArray
            .filter(res =>
                Object.keys(res.props)
                    .filter(resKey => resKey.match('^has[A-Z]'))
                    .find(key => res.props[key] === true))
            .map(res => res.props.name)
        if (!availableResources.some(res => res === currentResource)) {
            location.href = `/#/${availableResources[0]}`
        }
    }, [resources, router]);
};

export const getQueryStringParams = () => Array.from((new URLSearchParams(useLocation().search))).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
}, {});

export const useBQGetList = ({ resource, filter, sort, pagination, meta }) => {
    const dataProvider = useContext(DataProviderContext);
    const [responseData, setResponseData] = useState({ isLoading: true })

    useEffect(() => {
        dataProvider.getList(resource, {
            filter,
            sort,
            pagination,
            meta
        }).then(response => setResponseData({
            isLoading: false,
            data: response?.data ?? [],
        }))
    }, [])

    return responseData
}

export const useBQGetOne = ({ resource, id }) => {
    const dataProvider = useContext(DataProviderContext);
    const [responseData, setResponseData] = useState({ isLoading: true })

    useEffect(() => {
        dataProvider.getOne(resource, { id }).then(response => setResponseData({
            isLoading: false,
            data: response?.data,
        }))
    }, [])

    return responseData
}

export const useBQParams = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const params = Array.from(searchParams).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
    }, {});
    return params
}