import { ReactComponent as checkbox_on } from './ui-checkbox-full.svg'
import { ReactComponent as checkbox_off } from './ui-checkbox-empty.svg'
import { ReactComponent as checkbox_intermediate } from './ui-checkbox-intermediate.svg'
import { ReactComponent as iconSearch } from './icons-search.svg'
import { ReactComponent as iconRemoveHover } from './icons-remove-hover.svg'
import { ReactComponent as iconRemove } from './icons-remove.svg'
import { ReactComponent as iconEdit } from './icons-edit.svg'
import { ReactComponent as iconAdd } from './icons-add.svg'
import { ReactComponent as iconBack } from './icons-back.svg'
import { ReactComponent as iconLogout } from './icons-logout.svg'

// const reqSvgs = require.context ('./', true, /\.svg$/ )

// export const svgs = reqSvgs
//     .keys ()
//     .reduce ( ( images, path ) => {
//         images[path] = reqSvgs ( path )
//         return images
//     }, {} )

export const BQIcons = {
    iconBack,
    checkbox_on,
    checkbox_off,
    checkbox_intermediate,
    iconSearch,
    iconRemoveHover,
    iconRemove,
    iconEdit,
    iconAdd,
    iconLogout
}